import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";

import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    allStrapiBlogs(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        content
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        author
        date
      }
    }
  }
`;

const OurBlog = () => {
  const data = useStaticQuery(query);
  const {
    allStrapiBlogs: { nodes: blogs },
  } = data;
  return (
    <section className="blog-area">
      <div className="container">
        <div className="row">
          {blogs.map((blog, idx) => {
            return (
              <div className="col-lg-4 col-md-6" key={idx}>
                <div className="single-blog-post">
                  <div className="post-image">
                    <Link to={`/blogs/${blog.slug}`}>
                      <Image fluid={blog.image.childImageSharp.fluid} />
                    </Link>
                  </div>

                  <div className="post-content bg-fffbf5">
                    <ul className="post-meta d-flex justify-content-between align-items-center">
                      <li>
                        <div className="post-author d-flex align-items-center">
                          <img className="rounded-circle" alt="blog" />
                          <span>{blog.author}</span>
                        </div>
                      </li>
                      <li>
                        <i className="flaticon-calendar"></i> {blog.date}
                      </li>
                    </ul>
                    <h3>
                      <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                    </h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default OurBlog;
