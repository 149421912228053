import React from "react";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import Footer from "../../components/App/Footer";
import OurBlog from "../../components/Blog/OurBlog";

const Blog = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <OurBlog />
      </div>
      <Footer />
    </Layout>
  );
};

export default Blog;
